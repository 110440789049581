@import url(https://fonts.googleapis.com/css2?family=Mansalva&family=Notable&family=Ultra&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:wght@600;700&display=swap);
.App {
  text-align: center;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1, h2, h3, h4, p, a {
  font-family: 'Crimson Text', serif;
  font-weight: 600;
}

a {
  color: white;
}

.supportButton {
  font-size: 23px;
  font-weight: 600;
  background-color: white;
  color: #F24E56;
  border: 3px solid #F24E56;
  padding: 10px 30px;
  margin-bottom: 40px;
  box-shadow: -3px 4px 0px 0px #F24E56;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.supportButton:hover {
  transform: translateY(2px);
  box-shadow: 0px 0px 0px 0px #F24E56;
}

.supportButton:active {
  outline: none;
  color: white;
  background-color: #F24E56;
}

.supportButton:focus {
  outline: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Crimson Text', serif;
}

